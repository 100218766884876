import request from "../utils/request";
import Qs from "qs";
//省/市/区县承保统计数据详情
export const getUnderwriteStatisticsDetail = (data) => {
  return request({
    url: "underwrite/getUnderwriteStatisticsDetail",
    method: "post",
    data,
  });
};

//市、区县承保统计数据列表
export const getUnderwriteStatisticsList = (data) => {
  return request({
    url: "underwrite/getUnderwriteStatisticsList",
    method: "post",
    data,
  });
};

//省理赔统计数据详情
//市理赔统计数据详情
//区县理赔统计数据详情
export const getClaimStatisticsDetail = (data) => {
  return request({
    url: "claim/getClaimStatisticsDetail",
    method: "post",
    data,
  });
};

//市理赔统计数据列表
//区县理赔统计数据列表
export const getClaimStatisticsList = (data) => {
  return request({
    url: "claim/getClaimStatisticsList",
    method: "post",
    data,
  });
};

//导入理赔数据
export const importClaimData = (data) => {
  return request({
    url: "claim/importClaimData",
    method: "post",
    data,
  });
};

//更新承保数据
export const underwriteUpdate = (data) => {
  return request({
    url: "/underwrite/updateUnderwrite",
    method: "post",
    data,
  });
};

//获取保障方案内容
export const getInsurancePlan = (data) => {
  return request({
    url: "underwrite/getInsurancePlan",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//根据父级获取子级残联组织
//根据父级获取子级保险公司
export const getpageListbyparent = (data) => {
  return request({
    url: "disabilityfederation/getpageListbyparent",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

export const getInsurancecompanyList = (data) => {
  return request({
    url: "insurancecompany/getpageListbyparent",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//保存或者编辑残联机构
export const saveorupdatedisabilityfederation = (data) => {
  return request({
    url: "disabilityfederation/saveorupdatedisabilityfederation",
    method: "post",
    data,
  });
};

//保存或者编辑保险公司
export const saveorupdateinsurancecompany = (data) => {
  return request({
    url: "insurancecompany/saveorupdateinsurancecompany",
    method: "post",
    data,
  });
};
//承保中心
export const getUnderwriteList = (data) => {
  return request({
    url: "underwrite/getUnderwriteList",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};
export const getUnderWritePeopleList = (data) => {
  return request({
    url: "underwrite/getUnderWritePeopleList",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

export const getUnderwriteDetail = (data) => {
  return request({
    url: "underwrite/getUnderwriteDetail",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//获取案件列表
export const getClaimList = (data) => {
  return request({
    url: "claim/getClaimList",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//获取案件详情
export const getClaimDetailBy = (data) => {
  return request({
    url: "claim/getClaimDetailBy",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//获取资讯中心/保险家园
export const getinformationlist = (data) => {
  return request({
    url: "information/getinformationlist",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//删除咨询/保险家园
export const deleteinformation = (data) => {
  return request({
    url: "information/deleteinformation",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//保存保险家园
export const saveorupdateinfo = (data) => {
  return request({
    url: "information/saveorupdateinfo",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//获取咨询/保险家园详情
export const getinfobyid = (data) => {
  return request({
    url: "information/getinfobyid",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//获取市列表
export const getCityList = (data) => {
  return request({
    url: "common/getCityList",
    method: "post",
    data,
  });
};

//获取区县列表
export const getDistrictListOfCity = (data) => {
  return request({
    url: "common/getDistrictListOfCity?cityCode=" + data.cityCode,
    method: "post",
    data,
  });
};

//上传文件
export const uploadFile = (data) => {
  return request({
    url: "common/uploadFile",
    method: "post",
    data,
  });
};

//获取图形验证码
export const getImageVerificationCode = (data) => {
  return request({
    url: "common/getImageVerificationCode?randomCode=" + data.randomCode,
    method: "get",
    responseType: "blob",
  });
};
//登录
export const login = (data) => {
  return request({
    url: "login",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};

//登出
export const logout = (data) => {
  return request({
    url: "logout",
    method: "post",
    data,
  });
};

//第三方保险公司/获取父级获取子级接口
export const getOtherThirdcompany = (data) => {
  return request({
    url: "thirdcompany/getpageListbyparent",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    },
    data: Qs.stringify(data),
  });
};
export const saveOrEdirOtherThirdcompany = (data) => {
  return request({
    url: "thirdcompany/saveorupdatethirdcompany",
    method: "post",
    data,
  });
};
