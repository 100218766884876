import Vue from "vue";
import App from "./App.vue";
import * as echarts from 'echarts';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/reset.css";
import "./assets/font/iconfont.css";
import GeminiScrollbar from "vue-gemini-scrollbar";
import tinymce from "vue-tinymce-editor";
import MTitleI from "./components/MTitle-i.vue";
import VueParticles from "vue-particles";

import router from "./router";
import store from "./store";
import "./utils/rem.js";
import {moneyFormat} from "./utils/index.js";
import "../static/tinymce/langs/zh_CN.js";
import Base64 from "js-base64";

Vue.component("tinymce", tinymce);
Vue.component("MTitleI", MTitleI);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;
Vue.use(GeminiScrollbar);
Vue.use(ElementUI);
Vue.use(VueParticles);
Vue.use(Base64);
Vue.filter("moneyFormat",moneyFormat);
//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  const role = localStorage.getItem('token');
  if (!role && to.path !== '/login') {
    next('/login');
  } else {
    if (to.query.flag === '1') {
      location.reload();
    }
    if (role && to.path === '/login') {
      next('/underwriting_data');
    } else {
      next();
    }
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
