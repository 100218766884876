import {
  SET_CLAIM_LIST,
  SET_PAGATION_INDEMNITY,
  SET_CLAIM_DETAILBY,
} from "../mutation-types";
import { getClaimList, getClaimDetailBy } from "../../api";
import { moneyFormat } from "../../utils/index";
const reasonsArr = ["交通事故", "跌倒扭伤", "自然灾害", "重大疾病", "一般疾病", "其他", "疾病身故", "意外身故"];
export default {
  state: {
    pagation: {
      total: 0,
      currentPage: 1,
    },
    claimList: [], //案件列表
    claimDetailBy: null, //案件详情
  },
  mutations: {
    //案件列表
    [SET_CLAIM_LIST](state, data) {
      state.claimList = data;
    },
    //分页
    [SET_PAGATION_INDEMNITY](state, data) {
      state.pagation = {
        ...state.pagation,
        ...data,
      };
    },
    //案件详情
    [SET_CLAIM_DETAILBY](state, data) {
      state.claimDetailBy = data;
    },
  },
  actions: {
    //案件详情
    async getClaimDetailByAction({ commit, state }, params) {
      let result = await getClaimDetailBy(params);
      let details = resultCallback(result);
      //根据要求，underWrite也要用到，所以挂到claim下面
      details.claim.underWrite = details.underWrite ? details.underWrite : {};
      let insurancePlanTypeMap = {
        "0": "待续转",
        "1": "方案一",
        "2": "方案二",
        "3": "方案三",
        "4": "方案四",
      };
      details.claim.underWrite.insurancePlanTypeStr = (details.claim.underWrite ? 
        insurancePlanTypeMap[details.claim.underWrite.insurancePlanType] : '');
      details.claim.underWrite.insuranceTime =
        details.claim.underWrite ? details.claim.underWrite.insuranceStartTime : '' +
        "-" +
        details.claim.underWrite ? details.claim.underWrite.insuranceEndTime : '';
      //案件详情
      commit(SET_CLAIM_DETAILBY, claimDetailFormat(details.claim));
    },
    //案件列表
    async getClaimListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 10,
        startTime: params.year + "-01-01 00:00:00",
        endTime: params.year + "-12-31 23:59:59",
        ...params,
      };
      let result = await getClaimList(_params);
      const { total, currentPage, rows } = resultCallback(result);
      let pagation = {
        total,
        currentPage,
      };
      state.pagation = pagation;
      commit(SET_CLAIM_LIST, accidentReasonFormat(rows));
    },
    //分页信息
    setPagationAction({ commit }, data) {
      commit(SET_PAGATION_INDEMNITY, data);
    },
  },
};

//理赔信息金额格式化
function claimDetailFormat(data) {
  data.timeLimit = `${data.insuranceStartTime} 至 ${data.insuranceEndTime}`;
  data.reasonsStr = reasonsFormat(data.accidentReason);
  if (data.lossAssessmentAmount != null)
    data.lossAssessmentAmount = data.lossAssessmentAmount.toFixed(2) + " 元";
  if (data.medicineIndemnity != null)
    data.medicineIndemnity = data.medicineIndemnity.toFixed(2) + " 元";
  if (data.disabilityIndemnity != null)
    data.disabilityIndemnity = data.disabilityIndemnity.toFixed(2) + " 元";
  if (data.deathIndemnity != null)
    data.deathIndemnity = data.deathIndemnity.toFixed(2) + " 元";
  if (data.accidentHospitalizationBenefitIndemnity != null)
    data.accidentHospitalizationBenefitIndemnity =
      data.accidentHospitalizationBenefitIndemnity.toFixed(2) + " 元";
  if (data.seriousDiseaseIndemnity != null)
    data.seriousDiseaseIndemnity =
      data.seriousDiseaseIndemnity.toFixed(2) + " 元";
  if (data.diseaseDeathIndemnity != null)
    data.diseaseDeathIndemnity = data.diseaseDeathIndemnity.toFixed(2) + " 元";
  if (data.diseaseHospitalizationMedicineIndemnity != null)
    data.diseaseHospitalizationMedicineIndemnity =
      data.diseaseHospitalizationMedicineIndemnity.toFixed(2) + " 元";
  if (data.finishCaseAmount != null)
    data.finishCaseAmount = data.finishCaseAmount.toFixed(2) + " 元";
  return data;
}

//出险原因格式化
function reasonsFormat(reasons) {
  if (!reasons) return "";
  if (reasons.length === 1) {
    return `${reasonsArr[Number(reasons) - 1]}`;
  }
  let idx = reasons.split(",");
  let str = "";
  idx.forEach((item, index) => {
    str += `${reasonsArr[Number(item) - 1]} ${
      index !== idx.length - 1 ? "，" : ""
    }`;
  });
  return str;
}

//案件状态格式化
function accidentReasonFormat(list) {
  list.forEach((item) => {
    item.accidentReasonStrs = reasonsFormat(item.accidentReason);
    //客户要求：
    //当案件状态为已赔款、已撤案、已拒赔状态时，展示结案金额。当案件状态为单证收集中、单证审核中、赔款支付中，展示估损金额
    if (
      item.caseStatus === "3" ||
      item.caseStatus === "4" ||
      item.caseStatus === "5"
    ) {
      item.amount = item.finishCaseAmount;
    } else if (
      item.caseStatus === "0" ||
      item.caseStatus === "1" ||
      item.caseStatus === "2"
    ) {
      item.amount = item.lossAssessmentAmount;
    }
    item.amount = moneyFormat(item.amount);
  });
  return list;
}

function resultCallback(res) {
  if (res.result) {
    return res.resultObject;
  }
}
