import {
  SET_TOTAL_NUMBER,
  SET_PEOPLE_PROPORTION,
  SET_PREMIUM_PROPORTION,
  SET_MONTH_PREMIUM,
  SET_CITY_LIST,
  SET_INSURANCE_PLAN_LIST,
  SET_UNDER_WRITE,
} from "../mutation-types";
import {
  getUnderwriteStatisticsDetail,
  getUnderwriteStatisticsList,
} from "../../api";
const defaultTotal = {
  //保费统计，投保人数统计
  totalPremium: 0,
  totalPeopleCounts: 0,
  unifyPremium: 0,
  disunityPremium: 0,
  unityPeopleCounts: 0,
  disunityPeopleCounts: 0,
};
import { moneyFormat } from "../../utils/index";
export default {
  // namespaced: true,
  state: {
    totalNumber: { ...defaultTotal },
    mapData: [],
    peopleProportion: [], //人数占比
    premiumProportion: [], //保费占比
    monthPremium: {
      seriesData: [],
      xData: [],
    }, //月保费
    cityList: [], //各市数据
    insurancePlanList: [], //保障方案列表
    underwrite: [], //区县级的保障数据
  },
  mutations: {
    //保费统计，投保人数统计
    [SET_TOTAL_NUMBER](state, data) {
      state.totalNumber = data;
    },
    //人数占比
    [SET_PEOPLE_PROPORTION](state, data) {
      state.peopleProportion = data;
    },
    //保费占比
    [SET_PREMIUM_PROPORTION](state, data) {
      state.premiumProportion = data;
    },
    //月保费
    [SET_MONTH_PREMIUM](state, data) {
      state.monthPremium = data;
    },
    //各市数据
    [SET_CITY_LIST](state, data) {
      state.cityList = data;
    },
    //保障方案列表
    [SET_INSURANCE_PLAN_LIST](state, data) {
      state.insurancePlanList = data;
    },
    //区县级的保障数据
    [SET_UNDER_WRITE](state, data) {
      state.underwrite = data;
    },
  },
  actions: {
    //获取承保大数据详情
    async _getUnderwriteStatisticsDetail({ commit, state }, params) {
      let _params = {
        startTime: params.year + "-01-01 00:00:00",
        endTime: params.year + "-12-31 23:59:59",
        ...params,
      };
      let result = await getUnderwriteStatisticsDetail(_params);
      const {
        totalPremium,
        totalPeopleCounts,
        unifyPremium,
        disunityPremium,
        unityPeopleCounts,
        disunityPeopleCounts,
        peopleProportion,
        premiumProportion,
        unityPremiumDivisionList,
        disunityPremiumDivisionList,
        insurancePlanList,
        underwrite,
      } = resultCallback(result);
      let cityList = await _getUnderwriteStatisticsList(_params);
      state.mapData = cityList[1];
      let totalNumber = clearNull({
        totalPremium,
        totalPeopleCounts,
        unifyPremium,
        disunityPremium,
        unityPeopleCounts,
        disunityPeopleCounts,
      });
      //各市数据
      commit(SET_CITY_LIST, cityListDataFormat(cityList[0]));
      //保费统计，投保人数统计
      commit(SET_TOTAL_NUMBER, totalNumber);
      //人数占比
      commit(SET_PEOPLE_PROPORTION, peopleProportionFormat(peopleProportion));
      //保费占比
      commit(
        SET_PREMIUM_PROPORTION,
        premiumProportionFormat(premiumProportion)
      );
      //月保费
      commit(
        SET_MONTH_PREMIUM,
        monthPremiumFormat(
          unityPremiumDivisionList,
          disunityPremiumDivisionList
        )
      );
      //保障方案列表
      commit(
        SET_INSURANCE_PLAN_LIST,
        insurancePlanListFormat(insurancePlanList)
      );
      // commit(SET_UNDER_WRITE, underwriteFormat(underwrite));
      //区县级的保障数据
      commit(SET_UNDER_WRITE, underwrite);
    },
  },
};

function insurancePlanListFormat(insurancePlanList = []) {
  if (insurancePlanList.length === 0 || !insurancePlanList) return;
  let tableList = insurancePlanList[0].insuranceContentList;
  let tableFlag = true;
  tableList = tableList.map((item) => {
    if (item.insuredAmount) {
      tableFlag = false;
    }
    return { ...item, n: item.insuranceContentName, n1: item.insuredAmount };
  });
  for (let i = 0; i < insurancePlanList.length; i++) {
    insurancePlanList[i].insuranceContentList.map((item) => {
      tableList.map((t) => {
        if (item.insuranceContentName === t.n && item.insuredAmount !== null) {
          t["n" + (i + 1)] = item.insuredAmount;
          t["name" + (i + 1)] = insurancePlanList[i].insurancePlanName;
        }
      });
    });
  }
  if (tableFlag) {
    tableList = [];
  }
  return tableList;
}

function cityListDataFormat(list = []) {
  list.forEach((item) => {
    if (!item.cityName) {
      item.cityName = item.districtName;
    }
  });
  return list;
}

async function _getUnderwriteStatisticsList(params) {
  let result = await getUnderwriteStatisticsList(params);
  let list = resultCallback(result);
  let arr = [[], []];
  arr[0] = list;
  if (list.length === 0) {
    arr[1] = [{ ...defaultTotal }];
  }
  list.forEach((item) => {
    arr[1].push({
      isCity: item.cityCode,
      value: Number(item.cityCode) || Number(item.districtCode),
      parentCode: item.parentCode || 330000,
      name: item.cityName || item.districtName,
      totalPremium: moneyFormat(item.totalPremium) || 0,
      totalPeopleCounts: moneyFormat(item.totalPeopleCounts) || 0,
      unifyPremium: moneyFormat(item.unifyPremium) || 0,
      disunityPremium: moneyFormat(item.disunityPremium) || 0,
      unityPeopleCounts: moneyFormat(item.unityPeopleCounts) || 0,
      disunityPeopleCounts: moneyFormat(item.disunityPeopleCounts) || 0,
    });
  });
  return arr;
}
//月保费柱状图格式化
function monthPremiumFormat(data1 = [], data2 = []) {
  let arr = [[], []];
  let arr1 = [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月",
  ];
  arr1.forEach((item) => {
    arr[0].push(0);
    arr[1].push(0);
  });
  data1.forEach((item) => {
    let ystr = item.insuranceTimeStr;
    let idx = arr1.indexOf(ystr);
    if (idx !== -1) {
      arr[0][idx] = (item.totalPremium / 10000).toFixed(2);
    }
  });
  data2.forEach((item) => {
    let ystr = item.insuranceTimeStr;
    let idx = arr1.indexOf(ystr);
    if (idx !== -1) {
      arr[1][idx] = (item.totalPremium / 10000).toFixed(2);
    }
  });
  let json = {
    seriesData: arr,
    xData: arr1,
  };
  return json;
}

//保险方案保费占比结构 格式化
function premiumProportionFormat(obj) {
  if (!obj) return [];
  let keys = Object.keys(obj);
  let arr = [
    { name: "方案一", value: 0 },
    { name: "方案二", value: 0 },
    { name: "方案三", value: 0 },
    { name: "方案四", value: 0 },
    { name: "待续转", value: 0 },
  ];
  keys.forEach((item) => {
    switch (item) {
      case "planOnePremium":
        arr[0].value = obj[item] || null;
        break;
      case "planTwoPremium":
        arr[1].value = obj[item] || null;
        break;
      case "planThreePremium":
        arr[2].value = obj[item] || null;
        break;
      case "planFourPremium":
        arr[3].value = obj[item] || null;
        break;
      default:
        arr[4].value = obj[item] || null;
        break;
    }
  });
  return arr;
}
//保险方案人数占比结构 格式化
function peopleProportionFormat(obj) {
  if (!obj) return [];
  let keys = Object.keys(obj);
  let arr = [
    { name: "方案一", value: 0 },
    { name: "方案二", value: 0 },
    { name: "方案三", value: 0 },
    { name: "方案四", value: 0 },
    { name: "待续转", value: 0 },
  ];
  keys.forEach((item) => {
    switch (item) {
      case "planOnePeopleCounts":
        arr[0].value = obj[item] || null;
        break;
      case "planTwoPeopleCounts":
        arr[1].value = obj[item] || null;
        break;
      case "planThreePeopleCounts":
        arr[2].value = obj[item] || null;
        break;
      case "planFourPeopleCounts":
        arr[3].value = obj[item] || null;
        break;
      default:
        arr[4].value = obj[item] || null;
        break;
    }
  });
  return arr;
}
//清除 格式化
function clearNull(obj) {
  let newObj = {};
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (obj[i]) newObj[i] = obj[i];
      if (!obj[i]) newObj[i] = 0;
    }
  }
  return newObj;
}
//api 是否返回ture
function resultCallback(res) {
  if (res && res.result) {
    return res.resultObject;
  }
}
