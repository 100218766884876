import { SET_INFORMATION_LIST, SET_INFOBYID_DETAIL } from "../mutation-types";
import {
  getinformationlist,
  saveorupdateinfo,
  deleteinformation,
  getinfobyid,
} from "../../api";
export default {
  state: {
    pagation: {
      total: 100,
      currentPage: 1,
    },
    informationList: [], //保险家园list
    informationDetail: null, //保险家园detail
  },
  mutations: {
    //保险家园list
    [SET_INFORMATION_LIST](state, data) {
      state.informationList = data;
    },
    //保险家园detail
    [SET_INFOBYID_DETAIL](state, data) {
      state.informationDetail = data;
    },
  },
  actions: {
    //保险家园detail
    async getInformationDetailAction({ commit }, params) {
      let res = await getinfobyid(params);
      let result = resultCallback(res);
      result.resultInfo.listFile = result.listFile;
      commit(SET_INFOBYID_DETAIL, result.resultInfo);
    },
    //保存编辑
    async saveorupdateinfoAction({}, params) {
      let result = await saveorupdateinfo(params);
      return result;
    },
    //删除
    async deleteinformationAction({}, params) {
      let result = await deleteinformation(params);
      return result;
    },
    //保险家园list
    async getinformationListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 100,
        informationType: 2,
        ...params,
      };
      let result = await getinformationlist(_params);
      const { total, currentPage, rows } = resultCallback(result);
      let pagation = {
        total,
        currentPage,
      };
      state.pagation = pagation;
      commit(SET_INFORMATION_LIST, rows);
    },
  },
};

function resultCallback(res) {
  if (res.result) {
    return res.resultObject;
  }
}
