import {
  SET_ADVISORY_LIST,
  SET_PAGATION_ADVISORY,
  SET_ADVISORY_DETAIL,
} from "../mutation-types";
import {
  getinformationlist,
  saveorupdateinfo,
  deleteinformation,
  getinfobyid,
} from "../../api";
export default {
  state: {
    pagation: {
      total: 100,
      currentPage: 1,
    },
    advisoryList: [], //咨询列表
    advisoryDetail: null, //咨询详情
  },
  mutations: {
    //咨询列表
    [SET_ADVISORY_LIST](state, data) {
      state.advisoryList = data;
    },
    //分页
    [SET_PAGATION_ADVISORY](state, data) {
      state.pagation = {
        ...state.pagation,
        ...data,
      };
    },
    //咨询详情
    [SET_ADVISORY_DETAIL](state, data) {
      state.advisoryDetail = data;
    },
  },
  actions: {
    //咨询详情
    async getAdvisoryDetailAction({ commit, state }, params) {
      let res = await getinfobyid(params);
      let result = resultCallback(res);
      commit(SET_ADVISORY_DETAIL, result.resultInfo);
    },
    // async saveorupdateinfoAction({}, params) {
    //   let result = await saveorupdateinfo(params);
    //   return result;
    // },
    //删除
    async deleteAdvisoryAction({}, params) {
      let result = await deleteinformation(params);
      return result;
    },
    //咨询列表
    async getAdvisoryListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 100,
        informationType: 1,
        ...params,
      };
      let result = await getinformationlist(_params);
      const { total, currentPage, rows } = resultCallback(result);
      let pagation = {
        total,
        currentPage,
      };
      state.pagation = pagation;
      commit(SET_ADVISORY_LIST, rows);
    },
    //分页
    setPagationAction({ commit }, data) {
      commit(SET_PAGATION_ADVISORY, data);
    },
  },
};

function resultCallback(res) {
  if (res.result) {
    return res.resultObject;
  }
}

function accidentReasonFormat(list) {
  let reasons = ["交通事故", "跌倒扭伤", "自然灾害", "重大疾病", "一般疾病"];
  list.forEach((item) => {
    let accidentReason = item.accidentReason;
    if (!accidentReason) {
      item.accidentReasonStrs = [];
    }
    if (accidentReason && accidentReason.length > 1) {
      let idx = accidentReason.split(",");
      let arr = [];
      idx.forEach((item2) => {
        arr.push(reasons[Number(item2) - 1]);
      });
      item.accidentReasonStrs = arr;
    } else {
      item.accidentReasonStrs = [reasons[Number(accidentReason) - 1]];
    }
  });
  return list;
}
