import {
  getCityList,
  getDistrictListOfCity,
  getpageListbyparent,
  getInsurancecompanyList,
  saveorupdateinsurancecompany,
  getOtherThirdcompany,
  saveOrEdirOtherThirdcompany,
  getImageVerificationCode,
  login,
  logout,
} from "../../api";
export default {
  state: {
    districtFederationId: "", //	区残联机构Id
    cityFederationId: "", //市残联机构Id
    dateTime: localStorage.getItem('dateTime') || "", //登陆时间
    userInfo: JSON.parse(localStorage.getItem('user')) || {},
    cityList: [], //城市列表
    districtList: [], //区县列表
    disabilityfederationList: [], //市残联机构
    districtfederationList: [], //区县残联机构
    insurancecompanyList: [], //保险公司
    otherThirdcompanyList: [], //第三方保险公司
    years: new Date().getFullYear(), // 年份
  },
  mutations: {
    //用户信息
    setUserInfoMt(state, data) {
      state.userInfo = {
        ...state.userInfo,
        ...data,
      };
      if (!data.randomCode) {
        localStorage.setItem('user', JSON.stringify(state.userInfo));
      }
    },
    setYear(state, data) {
      state.years = data;
    },
    //用户信息
    setLogoutMt(state) {
      if (localStorage.getItem("user")) {
        localStorage.removeItem("user");
      }
      if (localStorage.getItem("dateTime")) {
        localStorage.removeItem("dateTime");
      }
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
      }
      state = {};
    },
    //城市列表
    setCityListMt(state, data) {
      state.cityList = data;
    },
    //区县地区数据
    setDistrictListMt(state, data) {
      state.districtList = data;
    },
    //市残联list
    setDisabilityfederationListMt(state, data) {
      state.disabilityfederationList = data;
    },
    //保险公司
    setInsurancecompanyListMt(state, data) {
      state.insurancecompanyList = data;
    },
    //区县残联list
    setDistrictfederationListMt(state, data) {
      state.districtfederationList = data;
    },
    //第三方保险公司
    setOtherThirdcompanyListMt(state, data) {
      state.otherThirdcompanyList = data;
    },
  },
  actions: {
    //退出
    logoutAction({ commit, state }) {
      return logout();
    },
    // 退出登陆删除用户信息
    setLogoutAction({ commit }) {
      commit("setLogoutMt");
    },
    //验证码
    getVerificationCodeAction({ commit, state }, params) {
      let randomCode = new Date().getTime();
      commit("setUserInfoMt", { randomCode });
      return getImageVerificationCode({ randomCode, ...params });
    },
    //登录
    loginAction({ commit, state }, params) {
      let _params = {
        randomCode: state.userInfo["randomCode"],
        ...params,
      };
      return login(_params);
    },
    //保存或者编辑保险公司
    async saveorupdateinsurancecompanyAction({ commit, state }, params) {
      let result = await saveorupdateinsurancecompany(params);
      return result;
    },
    //第三方保险公司
    async saveOrEdirOtherThirdcompanyAction({ commit, state }, params) {
      let result = await saveOrEdirOtherThirdcompany(params);
      return result;
    },
    //第三方保险公司
    async getOtherThirdcompanyListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 10,
        ...params,
      };
      let result = await getOtherThirdcompany(_params);
      let list = resultCallback(result);
      list.rows[0].federationName = "广润保险经纪有限公司";
      commit("setOtherThirdcompanyListMt", list.rows);
    },
    //城市list
    async getCityListAction({ commit, state }, params) {
      let result = await getCityList(params);
      let list = resultCallback(result);
      list.unshift({ cityName: "全部", cityCode: "all" });
      commit("setCityListMt", list);
    },
    //区县
    async getDistrictListAction({ commit, state }, params) {
      let list = [];
      if (params) {
        let result = await getDistrictListOfCity(params);
        list = resultCallback(result);
      }
      list.unshift({ districtName: "全部", districtCode: "all" });
      commit("setDistrictListMt", list);
    },
    //获取市残联
    async getPageListbyparentAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 100,
        ...params,
      };
      let result = await getpageListbyparent(_params);
      let list = resultCallback(result);
      commit("setDisabilityfederationListMt", list.rows);
    },
    //获取区县残联
    async getDistrictfederationListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 100,
        ...params,
      };
      let result = await getpageListbyparent(_params);
      let list = resultCallback(result);
      commit("setDistrictfederationListMt", list.rows);
    },
    async getInsurancecompanyListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 100,
        ...params,
      };
      let result = await getInsurancecompanyList(_params);
      let list = resultCallback(result);
      //list.rows第一个总是null 导致报错
      commit(
        "setInsurancecompanyListMt",
        list.rows.filter((e) => e)
      );
    },
    //用户信息
    setUserInfoAction({ commit }, data) {
      commit("setUserInfoMt", data);
    },
    // 年份
    setYearAction({ commit }, data) {
      commit("setYear", data);
    },
  },
};

function resultCallback(res) {
  if (res.result) {
    return res.resultObject;
  }
}
