<template lang="pug">
  .m-title-box(:class="className")
    div 
      span {{title}}
      span.mt-i
    div
      span(v-if="caseNo") 案件号：{{caseNo}}
</template>

<script>
export default {
  props: {
    title: String,
    caseNo: String,
    className: String,
  },
};
</script>

<style lang="less" scoped>
.m-title-box {
  .df;
  .dfac;
  color: #fff;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
}
.mt-i {
  display: inline-block;
  width: 112px;
  height: 18px;
  background: url("../assets/icon/m-title-i.png") 0 0 no-repeat;
  background-size: 100% 100%;
  margin-left: 10px;
}
</style>
