import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
/*//获取原型对象上的push函数
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};*/
export default new Router({
  routes: [
    {
      path: "/login",
      component: () => import("@/views/login/index.vue"),
    },
    {
      path: "/",
      name: "",
      redirect: "login",
      component: () => import("@/layout/index.vue"),
      children: [
        {
          path: "underwriting_data",
          name: "承保大数据",
          meta: {
            type: "",
          },
          component: () => import("@/views/underwriting_data/index.vue"),
        },
        {
          path: "claim_data",
          name: "理赔大数据",
          meta: {
            type: "",
          },
          component: () => import("@/views/claim_data/index.vue"),
        },
        {
          path: "organization",
          name: "组织保障",
          meta: {
            type: "",
          },
          redirect: "organization/company",
          component: () => import("@/views/organization/index.vue"),
          children: [
            {
              path: "company",
              name: "organization/company",
              meta: {
                type: "",
              },
              component: () => import("@/views/organization/company.vue"),
            },
            {
              path: "list",
              name: "organization/list",
              meta: {
                type: "",
              },
              component: () => import("@/views/organization/list.vue"),
            },
          ],
        },
        {
          path: "advisory_center",
          name: "咨询中心",
          meta: {
            type: "",
          },
          redirect: "advisory_center/list",
          component: () => import("@/views/advisory_center/index.vue"),
          children: [
            {
              path: "list",
              name: "advisory_center/list",
              meta: {
                type: "",
              },
              component: () => import("@/views/advisory_center/list.vue"),
            },
            {
              path: "detail",
              name: "advisory_center/detail",
              meta: {
                type: "",
              },
              component: () => import("@/views/advisory_center/detail.vue"),
            },
          ],
        },
        {
          path: "insurance_home",
          name: "保险家园",
          meta: {
            type: "",
          },
          redirect: "insurance_home/list",
          component: () => import("@/views/insurance_home/index.vue"),
          children: [
            {
              path: "detail",
              name: "insurance_home/detail",
              meta: {
                type: "",
              },
              component: () => import("@/views/insurance_home/detail.vue"),
            },
            {
              path: "list",
              name: "insurance_home/list",
              meta: {
                type: "",
              },
              component: () => import("@/views/insurance_home/list.vue"),
            },
          ],
        },
        {
          path: "indemnity_center",
          name: "赔案中心",
          redirect: "indemnity_center/list",
          component: () => import("@/views/indemnity_center/index.vue"),
          children: [
            {
              path: "detail",
              name: "indemnity_center/detail",
              meta: {
                type: "",
              },
              component: () => import("@/views/indemnity_center/detail.vue"),
            },
            {
              path: "list",
              name: "indemnity_center/list",
              meta: {
                type: "",
              },
              component: () => import("@/views/indemnity_center/list.vue"),
            },
          ],
        },
        {
          path: "underwriting_center",
          name: "承保中心",
          redirect: "underwriting_center/list",
          component: () => import("@/views/underwriting_center/index.vue"),
          children: [
            {
              path: "detail",
              name: "underwriting_center/detail",
              meta: {
                type: "",
              },
              component: () => import("@/views/underwriting_center/detail.vue"),
            },
            {
              path: "list",
              name: "underwriting_center/list",
              meta: {
                type: "",
              },
              component: () => import("@/views/underwriting_center/list.vue"),
            },
          ],
        },
      ],
    },
  ],
});
