import { SET_INSURANCE_CLIST } from "../mutation-types";
import { saveorupdatedisabilityfederation } from "../../api";
export default {
  state: {
    insuranceCList: [], //table list
  },
  mutations: {
    [SET_INSURANCE_CLIST](state, data) {
      state.insuranceCList = data;
    },
  },
  actions: {
    //编辑保存
    async saveorupdatedisabilityfederationAction({ commit, state }, params) {
      let result = await saveorupdatedisabilityfederation(params);
      return result;
    },
  },
};

function resultCallback(res) {
  if (res.result) {
    return res.resultObject;
  }
}
