export const SET_TOTAL_NUMBER = "SOME_MUTATION";
export const SET_PEOPLE_PROPORTION = "SET_PEOPLE_PROPORTION"; //peopleProportion
export const SET_PREMIUM_PROPORTION = "SET_PREMIUM_PROPORTION"; //premiumProportion
export const SET_MONTH_PREMIUM = "SET_MONTH_PREMIUM"; //monthPremium
export const SET_CITY_LIST = "SET_CITY_LIST"; //cityList
export const SET_INSURANCE_PLAN_LIST = "SET_INSURANCE_PLAN_LIST"; //insurancePlanList
export const SET_UNDER_WRITE = "SET_UNDER_WRITE"; //underwrite

export const SET_TOTAL_NUMBER_CLAIM = "SET_TOTAL_NUMBER_CLAIM";
export const SET_PERSON_TIMME_DIVISION = "SET_PERSON_TIMME_DIVISION"; //personTimeDivision
export const SET_PERSON_INDEMNITY_PREMIUM = "SET_PERSON_INDEMNITY_PREMIUM";
export const SET_CASE_DIVISION_LIST = "SET_CASE_DIVISION_LIST"; //caseDivisionList
export const SET_CASE_PREMIUM_LIST = "SET_CASE_PREMIUM_LIST"; //casePremiumDivisionList

export const SET_CLAIM_LIST = "SET_CLAIM_LIST"; //claimList
export const SET_PAGATION_INDEMNITY = "SET_PAGATION_INDEMNITY"; //claimList
export const SET_CLAIM_DETAILBY = "SET_CLAIM_DETAILBY"; //getClaimDetailBy

export const SET_UNDERWRITE_LIST = "SET_UNDERWRITE_LIST"; //underwriteList
export const SET_UNDERWRITE_PEOPLE_LIST = "SET_UNDERWRITE_PEOPLE_LIST"; //underwriteList
export const SET_PAGATION_UNDERWRITE = "SET_PAGATION_UNDERWRITE"; //claimList
export const SET_UNDERWRITE_DETAILBY = "SET_UNDERWRITE_DETAILBY"; //getClaimDetailBy
export const SET_INSURANCE_PLAN = "SET_INSURANCE_PLAN"; //getInsurancePlan
export const SET_PEOPLE_LIST = "SET_PEOPLE_LIST"; //peopleList

export const SET_INFORMATION_LIST = "SET_INSURANCE_PLAN"; //getinformationlist
export const SET_INFOBYID_DETAIL = "SET_INFOBYID_DETAIL"; //getinfobyid

export const SET_ADVISORY_LIST = "SET_ADVISORY_LIST"; //
export const SET_PAGATION_ADVISORY = "SET_PAGATION_ADVISORY"; //
export const SET_ADVISORY_DETAIL = "SET_ADVISORY_DETAIL"; //

export const SET_INSURANCE_CLIST = "SET_INSURANCE_CLIST"; //insuranceCList
