import axios from "axios";
import router from './../router'
import store from '../store/modules/app'
console.log("store",store)

axios.defaults.headers.post["Content-Type"] = "application/json";
const instance = axios.create({
  // baseURL:process.env.VUE_APP_BASE_URL,
  // baseURL: "http://sit.dpfserv.datoubao365.cn/",
  baseURL:  process.env.NODE_ENV === "production" ? window.g.SERVICE_PATH : "/api",
    // process.env.NODE_ENV === window.g.SERVICE_PATH,
  timeout: 50000,
  headers: {
    'Authorization': store.state.userInfo.token ? store.state.userInfo.token : localStorage.getItem('token')
  },
});
console.log("instance", instance)

//添加请求拦截器，在请求响应被then或catch处理前拦截它们
instance.interceptors.request.use(
  (config) => {
    //在发送请求之前做些什么
    const token = store.state.userInfo.token ? store.state.userInfo.token : localStorage.getItem('token');
    const dateTime = store.state.userInfo.dateTime ? store.state.userInfo.dateTime : localStorage.getItem('dateTime');
    // console.log("请求前token",token)
    // console.log("请求前dateTime",dateTime)
    // 判断当前是否有token，有则在请求头上加上token 请求头重置token
    if (token) {
      config.headers.Authorization = token
    }
    if (dateTime && token && new Date().getTime() <= (Number(dateTime) + 30 * 60 * 1000)) {
      console.log("请求之前")
      localStorage.setItem('dateTime', new Date().getTime());
    } else {
      console.log("清空数据")
      if (localStorage.getItem("user")) {
        localStorage.removeItem("user");
      }
      if (localStorage.getItem("dateTime")) {
        localStorage.removeItem("dateTime");
      }
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
      }
      router.replace({ path: '/login' });
    }
    return config;
  },
);

//添加响应拦截器
instance.interceptors.response.use(
  (res) => {
    if (res.status === 200) {
      if (res.data.code == '111') {
        if (localStorage.getItem("user")) {
          localStorage.removeItem("user");
        }
        if (localStorage.getItem("dateTime")) {
          localStorage.removeItem("dateTime");
        }
        if (localStorage.getItem("token")) {
          localStorage.removeItem("token");
        }
        return router.replace({ path: '/login' });
      }
      return res.data;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
