import {
  SET_TOTAL_NUMBER_CLAIM,
  SET_PERSON_TIMME_DIVISION,
  SET_PERSON_INDEMNITY_PREMIUM,
  SET_CASE_DIVISION_LIST,
  SET_CASE_PREMIUM_LIST,
} from "../mutation-types";
import { getClaimStatisticsDetail, getClaimStatisticsList } from "../../api";
const defaultTotal = {
  totalCaseCounts: 0, //总案件总数
  totalClaimPeopleCounts: 0, //理赔总人次
  indemnityTotalPremium: 0, //已赔款总金额
  averageFinishCaseTime: 0, //平均结案时长
  simpleIndemnityRate: 0, //简单赔付率
  finishCaseRate: 0, //结案率
};
import { moneyFormat } from "../../utils/index";
export default {
  // namespaced: true,
  state: {
    totalNumber: { ...defaultTotal }, //统计
    personTimeDivisionList: [], //赔案原因-人次占比
    indemnityPremiumDivisionList: [], //赔案原因-赔款金额
    mapData: [], //地图数据
    //月理赔案件
    caseDivisionList: {
      seriesData: [],
      xData: [],
    },
    cityList: [], //市list
    casePremiumDivisionList: {
      //月赔款金额
      seriesData: [],
      xData: [],
    },
  },
  mutations: {
    //统计
    [SET_TOTAL_NUMBER_CLAIM](state, data) {
      state.totalNumber = {
        ...state.totalNumber,
        ...data,
      };
    },
    //赔案原因-人次占比
    [SET_PERSON_TIMME_DIVISION](state, data) {
      state.personTimeDivisionList = data;
    },
    //赔案原因-赔款金额
    [SET_PERSON_INDEMNITY_PREMIUM](state, data) {
      state.indemnityPremiumDivisionList = data;
    },
    //月理赔案件
    [SET_CASE_DIVISION_LIST](state, data) {
      state.caseDivisionList = data;
    },
    //月赔款金额
    [SET_CASE_PREMIUM_LIST](state, data) {
      state.casePremiumDivisionList = data;
    },
  },
  actions: {
    //理赔详情
    async _getClaimStatisticsDetail({ commit, state }, params) {
      let _params = {
        startTime: params.year + "-01-01 00:00:00",
        endTime: params.year + "-12-31 23:59:59",
        ...params,
      };
      let result = await getClaimStatisticsDetail(_params);
      const {
        totalCaseCounts, //总案件总数
        totalClaimPeopleCounts, //理赔总人次
        indemnityTotalPremium, //已赔款总金额
        averageFinishCaseTime, //平均结案时长
        simpleIndemnityRate, //简单赔付率
        finishCaseRate, //结案率
        personTimeDivisionList,
        indemnityPremiumDivisionList,
        caseDivisionList,
        casePremiumDivisionList,
      } = resultCallback(result);
      let cityList = await _getClaimStatisticsList(_params);
      state.cityList = cityListDataFormat(cityList[0]);
      state.mapData = cityList[1];
      let totalNumber = clearNull({
        totalCaseCounts,
        totalClaimPeopleCounts,
        indemnityTotalPremium,
        averageFinishCaseTime,
        simpleIndemnityRate,
        finishCaseRate,
      });
      //案件总数统计
      commit(SET_TOTAL_NUMBER_CLAIM, totalNumber);
      //赔案原因-人次占比
      commit(
        SET_PERSON_TIMME_DIVISION,
        personTimeDivisionFormat(personTimeDivisionList)
      );
      //赔案原因-赔款金额
      commit(
        SET_PERSON_INDEMNITY_PREMIUM,
        personTimeDivisionFormat(indemnityPremiumDivisionList, 1)
      );
      //月理赔案件
      commit(
        SET_CASE_DIVISION_LIST,
        caseDivisionListCaseCountsFormat(caseDivisionList)
      );
      //月赔款金额
      commit(
        SET_CASE_PREMIUM_LIST,
        caseDivisionIndemnityPremiumListFormat(casePremiumDivisionList)
      );
    },
  },
};

//列表数据格式化
function cityListDataFormat(list = []) {
  list.forEach((item) => {
    if (!item.cityName) {
      item.cityName = item.districtName;
    }
    item.finishCaseRate = cellFormatter(item.finishCaseRate);
    item.simpleIndemnityRate = cellFormatter(item.simpleIndemnityRate);
  });
  return list;
}
//添加 %
function cellFormatter(cellValue) {
  if (Number(cellValue) !== 0) {
    return `${cellValue} %`;
  }
}
//获取理赔大数据统计
async function _getClaimStatisticsList(params) {
  let result = await getClaimStatisticsList(params);
  let list = resultCallback(result);
  if (!list) list = [];
  let arr = [[], []];
  arr[0] = list;
  if (list.length === 0) {
    arr[1] = [{ ...defaultTotal }];
  }
  list.forEach((item) => {
    arr[1].push({
      isCity: item.cityCode,
      value: Number(item.cityCode) || Number(item.districtCode),
      parentCode: item.parentCode || 330000,
      name: item.cityName || item.districtName,
      totalCaseCounts: Number(item.totalCaseCounts) || 0,
      totalClaimPeopleCounts: Number(item.totalClaimPeopleCounts) || 0,
      indemnityTotalPremium: moneyFormat(item.indemnityTotalPremium) || 0,
      averageFinishCaseTime: Number(item.averageFinishCaseTime) || 0,
      simpleIndemnityRate: Number(item.simpleIndemnityRate) || 0,
      finishCaseRate: Number(item.finishCaseRate) || 0,
    });
  });
  return arr;
}
//格式化 饼图数据
function caseDivisionListCaseCountsFormat(data = []) {
  let json = {
    seriesData: [],
    xData: [],
  };
  if (!data) return json;
  data.forEach((item) => {
    json.seriesData.push(item.caseCounts);
    json.xData.push(item.caseTimeStr);
  });
  return json;
}
//格式化 饼图数据
function caseDivisionIndemnityPremiumListFormat(data = []) {
  let json = {
    seriesData: [],
    xData: [],
  };
  if (!data) return json;
  data.forEach((item) => {
    json.seriesData.push((item.indemnityPremium / 10000).toFixed(2));
    json.xData.push(item.caseTimeStr);
  });
  return json;
}

function personTimeDivisionFormat(list, type = 0) {
  if (!list) return [];
  let arr = [];
  list.forEach((item) => {
    arr.push({
      name: item.divisionName,
      value:
        type === 0
          ? Number(item.claimPeopleCounts) || null
          : Number(item.indemnityPremium) || null,
    });
  });
  return arr;
}
//清除 null
function clearNull(obj) {
  let newObj = {};
  for (let i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (obj[i]) newObj[i] = obj[i];
      if (!obj[i]) newObj[i] = 0;
    }
  }
  return newObj;
}
//api 成功的回调
function resultCallback(res) {
  if (res.result) {
    return res.resultObject;
  }
}
