<template>
  <!--客户要登录页面不显示全局的背景图-->
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="less">
@font-face {
  font-family: cheap;
  src: url("./assets/font/digifacewide.ttf");
}
#app {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, Arial,
    "Hiragino Sans GB", "Microsoft Yahei", "微软雅黑", "STHeiti", "华文细黑",
    "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
.box-top-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  & > span:last-child {
    color: #5396f8;
    letter-spacing: 10px;
    padding: 0 0 0 10px;
    font-size: 22px;
  }
}
.index-w,
.page-w {
  padding: 20px;
  width: 100%;
  height: 100%;
}
.index-c-w {
  height: 100%;
  .main {
    height: 100%;
  }
}
.my-datetime-picker,
.my-input,
.my-select {
  color: #305baa;
  font-size: 14px;
  height: 100%;
  width: 100%;
  .el-input {
    height: 100%;
  }
  .el-input__inner {
    height: 100%;
  }
}
.cur-btn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  background: url("./assets/image/button2.png");
  background-size: 100% 100%;
  border-radius: 2px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  &:hover {
    background: url("./assets/image/button2_hover.png");
    background-size: 100% 100%;
  }
}
.btn-box {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  color: #fff;
  z-index: 666;
  & > div {
    width: 70px;
    height: 70px;
    text-align: center;
    border-radius: 50%;
    background: url("./assets/image/button1.png") 0 0 no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    &:hover {
      background: url("./assets/image/button1_hover.png") 0 0 no-repeat;
      background-size: 100% 100%;
    }
  }
  & > div:first-child {
    line-height: 70px;
  }
  & > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    span {
      line-height: 20px;
    }
  }
  .btn-view-list {
    width: 100%;
    height: 100%;
    display: flex;
    .dfc;
    align-items: center;
    .jcc;
  }
}

//解决e-table 渲染之后 theade 与tbody 宽度不一致的问题
.el-table th.gutter {
  display: table-cell !important;
}
.el-table colgroup.gutter {
  display: table-cell !important;
}
.my-datetime-picker {
  /deep/ .el-icon-time:before {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
// 修改el-date-picker默认样式
.el-time-panel,
.el-date-picker__time-header .el-input input,
.el-picker-panel__footer,
.el-picker-panel {
  background: rgba(0, 69, 145, 0.8) !important;
}
.el-time-spinner__item:hover:not(.disabled):not(.active) {
  background: #409eff !important;
}
.el-date-picker__time-header input,
.el-time-panel *,
.el-date-picker__header *,
.el-picker-panel__content * {
  color: #fff !important;
}
.popper__arrow::after,
.popper__arrow {
  border-bottom-color: #2677d5 !important;
}
.el-picker-panel__body-wrapper *::after {
  display: none !important;
}
.el-time-panel__content::after,
.el-time-panel__content::before,
.el-picker-panel__footer,
.el-picker-panel,
.el-picker-panel__body-wrapper * {
  border-color: #2677d5 !important;
}
.el-picker-panel__footer button {
  background: #2e91f7 !important;
  color: #fff !important;
  border-color: #2677d5 !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.el-icon-time:before {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
//修改select 下拉框默认背景色
.el-select-dropdown {
  border-color: #2677d5 !important;
}
.el-select-dropdown,
.el-select-dropdown__empty {
  background: rgba(0, 69, 145, 0.8) !important;
  color: #fff !important;
}
.el-select-dropdown__item {
  color: #fff !important;
}
.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #2677d5 !important ;
}
//修改el-message-box 默认样式

.el-message-box {
  background: rgba(10, 21, 78, 0.8) !important;
  box-shadow: inset 0px 0px 5px 3px #071b5a !important;
  border: none !important;
}
.el-message-box__title {
  color: #557eca !important;
  font-size: 20px !important;
}
.el-message-box__headerbtn .el-message-box__close {
  font-size: 24px !important;
  color: #5a86d3 !important;
}
.el-message-box__message {
  color: #5a86d3 !important;
}
.el-message-box__btns .el-button {
  width: 80px;
  height: 30px;
  border-radius: 2px;
  font-size: 14px;
}
.el-message-box__btns button:first-child {
  border: 1px solid #318ef0;
  color: #318ef0;
  background: transparent;
}
.el-message-box__btns .el-button--primary {
  background-color: transparent !important;
  background: url("./assets/image/button2.png") !important;
  background-size: 100% 100%;
  border: none;
  color: #fff;
  position: relative;
  top: -1px;
}

.el-button--primary:hover {
  background: url("./assets/image/button2_hover.png") !important;
  background-size: 100% 100% !important;
}

//修改input 样式

.el-upload,
.el-input__inner {
  background: transparent !important;
  border: none !important;
}
.el-input__inner {
  color: #fff !important;
}

.insurance-year .insurance-box .el-input .el-input__inner {
  color: #0493cd!important;
  font-size: 1.25rem;
}
//修改上传组件样式
.my-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px solid #2a54a6 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-uploader .el-icon-plus {
  font-size: 36px;
  color: #4987d9;
}

//修改 placeholder 字体颜色
input::-webkit-input-placeholder {
  color: #305baa !important;
}
</style>
