import {
  SET_UNDERWRITE_LIST,
  SET_UNDERWRITE_PEOPLE_LIST,
  SET_PAGATION_UNDERWRITE,
  SET_UNDERWRITE_DETAILBY,
  SET_INSURANCE_PLAN,
  SET_PEOPLE_LIST,
} from "../mutation-types";
import {
  getUnderwriteList,
  getUnderWritePeopleList,
  getUnderwriteDetail,
  getInsurancePlan,
  underwriteUpdate,
} from "../../api";
import { moneyFormat } from "../../utils/index";
export default {
  state: {
    pagation: {
      total: 0,
      currentPage: 1,
    },
    underwriteList: [], //table list
    underwriteDetail: null, //案件详情
    insurancePlan: null, //保险方案
    peopleList: [], //人员列表
  },
  mutations: {
    //table list
    [SET_UNDERWRITE_LIST](state, data) {
      state.underwriteList = data;
    },
    [SET_UNDERWRITE_PEOPLE_LIST](state, data) {
      state.peopleList = data;
    },
    //分页信息
    [SET_PAGATION_UNDERWRITE](state, data) {
      state.pagation = {
        ...state.pagation,
        ...data,
      };
    },
    //案件详情
    [SET_UNDERWRITE_DETAILBY](state, data) {
      state.underwriteDetail = data;
    },
    //保险方案
    [SET_INSURANCE_PLAN](state, data) {
      state.insurancePlan = data;
    },
    //人员列表
    [SET_PEOPLE_LIST](state, data) {
      state.peopleList = data;
    },
  },
  actions: {
    //更新承保数据
    async underwriteUpdateAction({ commit }, params) {
      let result = await underwriteUpdate(params);
      return result;
    },
    //获取保障方案内容
    async getInsurancePlanAction({ commit, state }, params) {
      let result = await getInsurancePlan(params);
      let details = resultCallback(result);
      commit(SET_INSURANCE_PLAN, details);
    },
    //承保数据详情
    async getUnderwriteDetailAction({ commit, state }, params) {
      let result = await getUnderwriteDetail(params);
      let details = resultCallback(result);
      commit(SET_UNDERWRITE_DETAILBY, details.underwrite);
      commit(SET_INSURANCE_PLAN, details.insurancePlan);
    },
    //承保list
    async getUnderwriteListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 10,
        startTime: params.year + "-01-01 00:00:00",
        endTime: params.year + "-12-31 23:59:59",
        ...params,
      };
      let result = await getUnderwriteList(_params);
      const { total, currentPage, rows } = resultCallback(result);
      let pagation = {
        total,
        currentPage,
      };
      state.pagation = pagation;
      commit(SET_UNDERWRITE_LIST, guaranteePeriodFormat(rows));
    },

    //人员list
    async getUnderWritePeopleListAction({ commit, state }, params) {
      let _params = {
        currentPage: 1,
        pageSize: 10,
        ...params,
      };
      let result = await getUnderWritePeopleList(_params);
      const { total, currentPage, rows } = resultCallback(result);
      let pagation = {
        total,
        currentPage,
      };
      state.pagation = pagation;
      commit(SET_UNDERWRITE_PEOPLE_LIST, peopleListSerial(rows,currentPage));
    },
    //分页信息
    setPagationAction({ commit }, data) {
      commit(SET_PAGATION_UNDERWRITE, data);
    },
  },
};

function resultCallback(res) {
  if (res.result) {
    return res.resultObject;
  }
}

function guaranteePeriodFormat(list) {
  list.forEach((item) => {
    item.guaranteePeriod = `${item.insuranceStartTime} 至 ${item.insuranceEndTime}`;
    item.premium = moneyFormat(item.premium);
    item.totalPeopleCounts = moneyFormat(item.totalPeopleCounts);
  });
  return list;
}

function peopleListSerial(list,currentPage) {
  list = list.map((t,i) => {
    t.index = (currentPage - 1) * 10 + i + 1;
    return t;
  })
  return list;
}